import { RetryBookingElexysRequestInterface } from "../contracts/RetryBookingElexysRequest";

export class RetryBookingElexys implements RetryBookingElexysRequestInterface {
  payload: any;

  constructor(payload: any) {
    this.payload = payload;
  }

  toJSON(): string {
    return JSON.stringify(this.payload);
  }
}
