
import { Options, Vue } from "vue-class-component";
import RadioButton from "primevue/radiobutton";
import { IntegrationMonitoringController } from "@/app/ui/controllers/IntegrationMonitoringController";
import { pastDateFromToday } from "@/app/infrastructures/misc/Utils";
import { RequestDownloadElexysFailedBooking } from "@/domain/entities/IntegrationMonitoring";

@Options({
  components: {
    RadioButton
  },
  emits: ["download"]
})
export default class ModalFormApproval extends Vue {
  // radio options
  dateRangeOptions = [
    { name: "Hari ini", value: "today" },
    { name: "3 hari terakhir", value: "last_three_days" },
    { name: "5 hari terakhir", value: "last_five_days" },
    { name: "7 hari terakhir", value: "last_seven_days" }
  ];
  dateRadioValue = this.dateRangeOptions[0].value; // default

  // mapping value radio to date
  get dateRange() {
    const today = new Date().toLocaleDateString("fr-CA");
    if (this.dateRadioValue === "last_three_days") {
      return new RequestDownloadElexysFailedBooking({
        firstFailedStart: pastDateFromToday(2),
        firstFailedEnd: today
      });
    }
    if (this.dateRadioValue === "last_five_days") {
      return new RequestDownloadElexysFailedBooking({
        firstFailedStart: pastDateFromToday(4),
        firstFailedEnd: today
      });
    }
    if (this.dateRadioValue === "last_seven_days") {
      return new RequestDownloadElexysFailedBooking({
        firstFailedStart: pastDateFromToday(6),
        firstFailedEnd: today
      });
    }
    // default hari ini
    return new RequestDownloadElexysFailedBooking({
      firstFailedStart: today,
      firstFailedEnd: today
    });
  }

  onDownload() {
    this.$emit("download", this.dateRange);
  }

  onClose() {
    IntegrationMonitoringController.setOpenModalFailedBooking(false);
  }
}
