
import { Vue, Options } from "vue-class-component";
import {
  ellipsisString,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import AdvancedFilter from "../component/advanced-filter/index.vue";
import debounce from "lodash/debounce";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import router from "../../../router";
import { IntegrationMonitoringController } from "@/app/ui/controllers/IntegrationMonitoringController";
import { ElexysFailedBookingData } from "@/domain/entities/IntegrationMonitoring";
import ModalDownloadFailed from "./components/modal-download-failed.vue";
import Notification from "@/app/ui/components/notification/index.vue";
@Options({
  components: {
    AdvancedFilter,
    EmptyState,
    Notification,
    ModalDownloadFailed
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class ElexysFailedBooking extends Vue {
  mounted() {
    IntegrationMonitoringController.initIntegrationMonitoringList();
    this.fetchBookingCommissionList();
  }

  // Modal retry booking
  get showModalRetryBooking() {
    return IntegrationMonitoringController.showModalRetryBooking;
  }

  elexysFailedBooking = {
    sttElexysNumber: "",
    elexysRequest: ""
  };

  setModalRetryBooking(params: boolean, item: ElexysFailedBookingData) {
    this.elexysFailedBooking.sttElexysNumber = item.sttElexysNumber;
    this.elexysFailedBooking.elexysRequest = item.elexysRequest;
    IntegrationMonitoringController.setModalRetryBooking(params);
  }

  retryBookingElexys() {
    IntegrationMonitoringController.retryBooking({
      payload: this.elexysFailedBooking.elexysRequest,
      callback: this.onSuccessRetryBooking
    });
  }

  // search
  onSearch(value: string) {
    if (!value || value.length >= 3) {
      IntegrationMonitoringController.setFilter({
        ...this.filter,
        search: value
      });
      this.fetchBookingCommissionList();
    }
  }

  clearSearch() {
    IntegrationMonitoringController.setFilter({
      ...this.filter,
      search: ""
    });
    this.fetchBookingCommissionList();
  }
  // sorting
  sortData = [
    { name: "Urutkan", value: "" },
    { name: "Terbaru", value: "DESC-updated_at" },
    { name: "Terlama", value: "ASC-updated_at" },
    { name: "Error id: A-Z", value: "ASC-id" },
    { name: "Error id: Z-A", value: "DESC-id" }
  ];
  sortSelect = false;
  get filter() {
    return IntegrationMonitoringController.filter;
  }
  onOpenSortSelect() {
    this.sortSelect = true;
  }
  onCloseSortSelect() {
    this.sortSelect = false;
  }
  onSelectSort(_: string, value: string) {
    IntegrationMonitoringController.setFilter({ ...this.filter, sort: value });
    this.fetchBookingCommissionList();
    this.onCloseSortSelect();
  }

  fetchBookingCommissionList() {
    IntegrationMonitoringController.getElexysFailedBookingList();
  }

  get dataList() {
    return IntegrationMonitoringController.elexysFailedBookingList.data;
  }
  get isLoading() {
    return IntegrationMonitoringController.isLoading;
  }

  get errorCause() {
    return IntegrationMonitoringController.errorCause;
  }

  get pagination() {
    return IntegrationMonitoringController.elexysFailedBookingList.pagination;
  }

  onRequest() {
    IntegrationMonitoringController.getElexysFailedBookingList();
  }

  goToCreate() {
    router.push("/tarif/commission-configuration/booking/create");
  }

  get account() {
    return AccountController.accountData;
  }

  get columns() {
    return [
      {
        name: "Error ID",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: ElexysFailedBookingData) => {
          return `<div class="overflow-ellipsis text-left">${item.id}</div>`;
        }
      },
      {
        name: "Suffix",
        styleHead: "w-24 text-left whitespace-no-wrap align-top",
        render: (item: ElexysFailedBookingData) => {
          return `<div class="overflow-ellipsis text-left">${item.suffix}</div>`;
        }
      },
      {
        name: "No. STT Elexys",
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: ElexysFailedBookingData) => {
          return `<div class="overflow-ellipsis text-left">${item.sttElexysNumber}</div>`;
        }
      },
      {
        name: "Alasan gagal",
        styleHead: "w-108 text-left whitespace-no-wrap align-top",
        render: (item: ElexysFailedBookingData) => {
          return `<div class="overflow-ellipsis text-left">${ellipsisString(
            item.failedReasons,
            250
          )}</div>`;
        }
      },
      {
        name: "Action",
        key: "download_column_button",
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        downloadList: (item: ElexysFailedBookingData) => {
          if (item.latestRetryStatus?.toLowerCase() !== "failed") {
            return;
          } else {
            return [
              {
                title: "Booking Ulang",
                icon: "rebooking",
                textColor: "red-lp-100",
                flat: true,
                action: () => this.setModalRetryBooking(true, item)
              },
              {
                title: "Salin Payload",
                icon: "copy-clipboard",
                textColor: "red-lp-100",
                flat: true,
                action: () => {
                  const input: any = document.body.appendChild(
                    document.createElement("input")
                  );
                  input.value = item.elexysRequest;
                  input.focus();
                  input.select();
                  document.execCommand("copy");
                  input.parentNode.removeChild(input);
                  this.setNotification();
                  this.notificationType = "copy";
                }
              }
            ];
          }
        }
      },
      {
        name: "Status Terakhir",
        key: "status",
        styleHead: "w-40 text-left align-top",
        styleBody: "",
        render: (item: ElexysFailedBookingData) => {
          return `
        <div class="flex"><div
        class="rounded-full px-2 py-0 ${this.chipsStatusStyle(
          item.latestRetryStatus
        )}"
        ><span class="capitalize">${item.latestRetryStatus?.toLowerCase()}</span></div></div>`;
        }
      },
      {
        name: "Tanggal gagal",
        styleHead: "w-44 text-left whitespace-no-wrap align-top",
        render: (item: ElexysFailedBookingData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.firstFailedAt
          )}</div>`;
        }
      },
      {
        name: "Tanggal diperbarui",
        styleHead: "w-44 text-left whitespace-no-wrap align-top",
        render: (item: ElexysFailedBookingData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.updatedAt
          )}</div>`;
        }
      }
    ];
  }

  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }
  // advanced filter
  get advancedFilterData() {
    return IntegrationMonitoringController.advanceFilterData;
  }
  get isAdvancedFilterData() {
    return (
      this.advancedFilterData.suffix.length ||
      this.advancedFilterData.status.length
    );
  }
  isAdvancedFilterOpened = false;
  onChangeAdvancedFilter = debounce(event => {
    IntegrationMonitoringController.setAdvanceFilterData(event);
    this.fetchBookingCommissionList();
  }, 500);
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onResetAdvancedFilter = debounce(() => {
    IntegrationMonitoringController.setAdvanceFilterData({
      suffix: [],
      status: []
    });
    this.fetchBookingCommissionList();
  }, 500);

  // modal failed booking
  get isOpenModalDownloadFailedBooking() {
    return IntegrationMonitoringController.isOpenModalDownloadFailedBooking;
  }
  openModalDownloadFailedBooking() {
    IntegrationMonitoringController.setOpenModalFailedBooking(true);
  }

  downloadFailedElexysBooking(dateRange: any) {
    IntegrationMonitoringController.downloadElexysFailedBooking({
      payload: dateRange,
      callback: this.onSuccessDownloadFailed
    });
  }

  onSuccessDownloadFailed() {
    this.setNotification();
    this.notificationType = "download";
  }

  // download
  onDownload() {
    this.openModalDownloadFailedBooking();
  }

  chipsStatusStyle(value: string) {
    switch (value?.toLowerCase()) {
      case "success":
        return "text-green-lp-200 bg-green-lp-300";
      case "on progress":
        return "text-yellow-lp-100 bg-yellow-lp-200";
      case "failed":
        return "text-red-lp-500 bg-red-lp-600";
      default:
        return "text-black-lp-400 bg-gray-lp-200";
    }
  }
  // notification
  notification = false;
  notificationType = "";
  setNotification() {
    this.notification = false;

    setTimeout(() => {
      this.notification = true;
    }, 10);
  }
  get customStyles() {
    switch (this.notificationType) {
      case "success":
        return {
          background: "bg-green-lp-400",
          text: "text-white",
          icon: "snackbar-success",
          message: "Download list elexys failed booking berhasil",
          width: "w-400px"
        };
      case "success-retry-booking":
        return {
          background: "bg-green-lp-400",
          text: "text-white",
          icon: "snackbar-success",
          message: `Booking ulang No. STT ${this.elexysFailedBooking.sttElexysNumber} berhasil !`,
          width: "w-400px"
        };
      case "copy":
        return {
          background: "bg-gray-lp-300",
          text: "text-white",
          message: "Payload berhasil disalin.",
          width: "w-300px"
        };
      case "download":
        return {
          background: "bg-green-lp-400",
          text: "text-white",
          icon: "snackbar-success",
          message: "Pengunduhan data berhasil !",
          width: "w-400px"
        };
      default:
        return {
          background: "bg-white",
          text: "text-black-lp-200"
        };
    }
  }

  onSuccessRetryBooking() {
    this.setNotification();
    this.notificationType = "success-retry-booking";
  }
}
