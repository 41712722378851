
import { Options, Vue } from "vue-class-component";
import Checkbox from "primevue/checkbox";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";
import RadioButton from "primevue/radiobutton";

@Options({
  emits: ["opened", "change", "reset", "update:modelValue"],
  components: {
    Checkbox,
    CustomDropdown,
    RadioButton
  },
  props: {
    isDisabled: {
      default: false,
      type: Boolean
    },
    isScroll: {
      default: false,
      type: Boolean
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class Filter extends Vue {
  isChevron = false;
  props: any = this.$props;
  openFilter = false;
  resetSelectSearch = 0;
  resetfilter() {
    this.filterData = {
      suffix: [],
      status: []
    };
    this.resetSelectSearch++;
    this.$emit("reset", "");
  }

  // filter data to be sent
  // important!! if modify this property, please update resetFilter() as well
  filterData = {
    suffix: [],
    status: []
  };

  get sumFilter() {
    return this.filterData.suffix.length + this.filterData.status.length;
  }

  onChange() {
    this.$emit("change", this.filterData);
    this.$emit("update:modelValue", this.filterData);
  }

  // suffix
  get suffixOptions() {
    return Array(10)
      .fill("")
      .map((_: any, i: number) => ({
        name: i,
        value: i
      }));
  }

  // status
  get statusOptions() {
    return [
      {
        name: "On Progress",
        value: "ONPROGRESS"
      },
      {
        name: "Success",
        value: "SUCCESS"
      },
      {
        name: "Failed",
        value: "FAILED"
      }
    ];
  }
}
