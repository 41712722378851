
import { Vue, Options } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import { IntegrationMonitoringController } from "@/app/ui/controllers/IntegrationMonitoringController";

@Options({
  components: {
    EmptyState,
    Skeleton
  }
})
export default class LogStt extends Vue {
  sttNo = "";

  mounted() {
    IntegrationMonitoringController.initLogStt();
  }

  get showSttDetail() {
    return IntegrationMonitoringController.showSttDetail;
  }

  handleError() {
    IntegrationMonitoringController.setError(false);
  }

  async trackStt() {
    if (this.sttNo) {
      await IntegrationMonitoringController.trackStt(this.sttNo);
    }
  }

  get isLoading() {
    return IntegrationMonitoringController.isLoading;
  }

  get isError() {
    return IntegrationMonitoringController.isError;
  }

  get errorCause() {
    return IntegrationMonitoringController.errorCause;
  }

  // table stt detail
  get columns() {
    return [
      {
        name: "Action",
        styleHead: "w-36 text-left whitespace-no-wrap align-top",
        render: (item: any) => {
          return `<div class="whitespace-no-wrap text-left">${item.action}</div>`;
        }
      },
      {
        name: "Request",
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        render: (item: any) => {
          return `<div class="text-left break-all">${item.request}</div>`;
        }
      },
      {
        name: "Response",
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        render: (item: any) => {
          return `<div class="text-left break-all">${item.response}</div>`;
        }
      }
    ];
  }

  pagination = {
    page: 1,
    limit: 100
  };

  //table shipment history
  get logStt() {
    return IntegrationMonitoringController.logStt;
  }

  get isShowAlertMessage() {
    return (
      this.isError &&
      (this.errorCause === "notfoundstt" || this.errorCause === "unusedstt")
    );
  }

  get alertMessage() {
    switch (this.errorCause) {
      case "notfoundstt":
        return {
          title: "Data Tidak Ditemukan",
          message: "Maaf, data yang Anda cari tidak ditemukan.",
          image: "data-not-found"
        };
      case "unusedstt":
        return {
          title: "STT Belum Digunakan",
          message: "STT yang Anda cari belum digunakan atau <i>unused</i>.",
          image: "data-not-found"
        };
      default:
        return {
          title: "",
          message: "",
          image: ""
        };
    }
  }
}
