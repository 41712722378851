
import { Options, Vue } from "vue-class-component";
import { ALLOWED } from "@/app/infrastructures/misc/RolePermission";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
@Options({
  components: {}
})
export default class BulkBooking extends Vue {
  // Tabs
  tabs = [
    {
      name: "elexys-failed-booking",
      title: "Elexys's Failed Booking",
      permission: ALLOWED
    },
    {
      name: "log-stt",
      title: "Log STT",
      permission: ALLOWED
    }
  ];
  get filteredTabs() {
    return this.tabs.filter((tab: any) => {
      return checkRolePermission(tab.permission);
    });
  }
  changeTabs(value: string) {
    this.$router.push({
      name: value
    });
  }
  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
