import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen w-full" }
const _hoisted_2 = { class: "flex flex-col justify-between items-start relative pt-8 pb-2" }
const _hoisted_3 = { class: "flex flex-row align-middle items-center justify-between w-full" }
const _hoisted_4 = { class: "text-20px text-black-lp-200 flex flex-row items-center flex-no-wrap relative" }
const _hoisted_5 = {
  key: 1,
  class: "mb-16 overflow-auto h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_AdvancedFilter = _resolveComponent("AdvancedFilter")!
  const _component_Select = _resolveComponent("Select")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_ModalDownloadFailed = _resolveComponent("ModalDownloadFailed")!
  const _component_Notification = _resolveComponent("Notification")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_Search, {
              onInput: _ctx.onSearch,
              onClear: _ctx.clearSearch,
              value: _ctx.filter.search,
              placeholder: "Cari"
            }, null, 8, ["onInput", "onClear", "value"]),
            _createVNode(_component_AdvancedFilter, {
              "custom-max-height": "60vh",
              "model-value": _ctx.advancedFilterData,
              isScroll: false,
              isDisabled: false,
              onChange: _ctx.onChangeAdvancedFilter,
              onOpened: _ctx.onOpenedAdvancedFilter,
              onReset: _ctx.onResetAdvancedFilter
            }, null, 8, ["model-value", "onChange", "onOpened", "onReset"]),
            _createVNode(_component_Select, {
              dataSelect: _ctx.sortData,
              onOpenSelect: _ctx.onOpenSortSelect,
              onCloseSelect: _ctx.onCloseSortSelect,
              onChooseSelect: _ctx.onSelectSort,
              value: _ctx.filter.sort,
              isOpenMenu: _ctx.sortSelect,
              class: "w-40 ml-2",
              isShowDefault: false
            }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"])
          ]),
          _createVNode(_component_lp_button, {
            onClick: _ctx.onDownload,
            title: "Download",
            textColor: "white"
          }, null, 8, ["onClick"])
        ])
      ]),
      (
        !_ctx.dataList.length &&
          !_ctx.isLoading &&
          !_ctx.errorCause &&
          !_ctx.isAdvancedFilterData &&
          !_ctx.filter.sort
      )
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 0,
            customMessage: "Oops.. Belum ada data yang dapat ditampilkan"
          }))
        : (_openBlock(), _createBlock("div", _hoisted_5, [
            _createVNode(_component_TableV2, {
              class: "w-full",
              columns: _ctx.columns,
              data: _ctx.dataList,
              loading: _ctx.isLoading,
              onRequest: _ctx.fetchBookingCommissionList,
              errorCause: _ctx.errorCause,
              pagination: _ctx.pagination,
              "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
              isRightFreeze: true,
              pinnedSubstractHeight: "290px",
              totalColumnFreeze: 0,
              borderTop: false
            }, null, 8, ["columns", "data", "loading", "onRequest", "errorCause", "pagination"])
          ]))
    ]),
    (_ctx.isOpenModalDownloadFailedBooking)
      ? (_openBlock(), _createBlock(_component_ModalDownloadFailed, {
          key: 0,
          onDownload: _ctx.downloadFailedElexysBooking
        }, null, 8, ["onDownload"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Notification, {
      modelValue: _ctx.notification,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.notification = $event)),
      isCustom: "",
      message: _ctx.customStyles.message,
      customStyles: _ctx.customStyles,
      icon: _ctx.customStyles.icon,
      isAutoDisplay: false,
      isAutoClose: "",
      timeout: 3,
      class: _ctx.customStyles.width,
      showIconClose: _ctx.notificationType === 'success-retry-booking'
    }, null, 8, ["modelValue", "message", "customStyles", "icon", "class", "showIconClose"]),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx.retryBookingElexys,
      onClose: () => _ctx.setModalRetryBooking(false, {}),
      title: "Konfirmasi Pembuatan",
      message: "Pastikan kembali, seluruh detail pengiriman sudah benar dan sesuai. Klik booking untuk booking ulang.",
      image: "are-you-sure",
      textSuccess: "Booking",
      textCancel: "Kembali",
      class: "px-8"
    }, null, 8, ["onSubmit", "onClose"]), [
      [_vShow, _ctx.showModalRetryBooking]
    ])
  ], 64))
}