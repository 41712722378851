import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  AdvancedFilterElexysFailedBooking,
  ElexysFailedBookingEntities,
  LogSttData,
  RequestApiElexysFailedBookingList,
  RequestDownloadElexysFailedBooking
} from "@/domain/entities/IntegrationMonitoring";
import { container } from "tsyringe";
import { IntegrationMonitoringPresenter } from "../presenters/IntegrationMonitoringPresenter";
import {
  FAILED_ERROR,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { MainAppController } from "./MainAppController";
import { RetryBookingElexys } from "@/data/payload/api/RetryBookingElexysApiRequest";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "integration_monitoring"
})
class IntegrationMonitoringStore extends VuexModule {
  public isLoading = false;
  public errorCause = "";
  public isOpenModalDownloadFailedBooking = false;
  public isSuccessDownloadFailedBooking = false;
  public showModalSuccessRetryBooking = false;
  public showModalRetryBooking = false;
  public elexysFailedBookingList = new ElexysFailedBookingEntities();
  public filter = {
    search: "",
    sort: ""
  };
  public advanceFilterData: AdvancedFilterElexysFailedBooking = {
    suffix: [],
    status: []
  };
  public pagination = {
    page: 1,
    limit: 10
  };

  isError = false;
  showSttDetail = false;
  public logStt = new LogSttData("", [], []);

  @Action
  public fetchElexysFailedBookingList(
    params: RequestApiElexysFailedBookingList
  ) {
    this.setLoading(true);
    const presenter = container.resolve(IntegrationMonitoringPresenter);
    presenter
      .getElexysFailedBookingList(params)
      .then((res: ElexysFailedBookingEntities) => {
        this.setElexysFailedBookingList(res);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public initIntegrationMonitoringList() {
    this.setElexysFailedBookingList(new ElexysFailedBookingEntities());
    this.setAdvanceFilterData(
      new AdvancedFilterElexysFailedBooking({
        suffix: [],
        status: []
      })
    );
    this.setFilter({ search: "", sort: "DESC-updated_at" });
  }

  @Action
  public getElexysFailedBookingList() {
    const sortby = this.filter.sort.split("-")[0];
    const orderby = this.filter.sort.split("-")[1];

    this.fetchElexysFailedBookingList(
      new RequestApiElexysFailedBookingList({
        page: this.elexysFailedBookingList.pagination.page,
        limit: this.elexysFailedBookingList.pagination.limit,
        search: this.filter.search,
        sortby,
        orderby,
        suffix: this.advanceFilterData.suffix.toString(),
        retrystatus: this.advanceFilterData.status.toString()
      })
    );
  }

  @Action
  public downloadElexysFailedBooking(params: {
    payload: RequestDownloadElexysFailedBooking;
    callback: any;
  }) {
    MainAppController.showLoading();
    const presenter = container.resolve(IntegrationMonitoringPresenter);
    presenter
      .downloadElexysFailedBooking(params.payload)
      .then(() => {
        this.setSuccessDownloadFailedBooking(true);
        this.setOpenModalFailedBooking(false);
        params.callback();
      })
      .catch(err =>
        MainAppController.showErrorMessage(parsingErrorResponse(err))
      )
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public async searchAction(value: string) {
    await this.setFilter({ ...this.filter, search: value });
    await this.setFirstPage();
    await this.getElexysFailedBookingList();
  }

  @Action
  public setFirstPage() {
    this.setFirst();
  }

  @Action
  public retryBooking(params: { payload: any; callback: any }) {
    this.setModalRetryBooking(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(IntegrationMonitoringPresenter);
    const payload = new RetryBookingElexys(JSON.parse(params.payload));
    presenter
      .retryBookingElexys(payload)
      .then(() => {
        this.setshowModalSuccessRetryBooking(true);
        params.callback();
      })
      .catch(async (err: any) => {
        MainAppController.showErrorMessage(
          !err.response || err.response?.data.status >= 500
            ? parsingErrorResponse(err)
            : new ErrorMessageEntities({
                type: FAILED_ERROR,
                title: "Pembuatan Gagal !",
                message: err.response.data.message.id
              })
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  async trackStt(sttNo: string) {
    this.setLoading(true);
    this.setShowSttDetail(false);
    const presenter = container.resolve(IntegrationMonitoringPresenter);
    presenter
      .getLogStt(sttNo)
      .then(res => {
        this.setLogStt(res);
        this.setShowSttDetail(true);
        this.setError(false);
        this.setErrorCause("");
        if (
          res.partnerlogGober.length === 0 &&
          res.partnerlogHydra.length === 0
        ) {
          this.setError(true);
          this.setErrorCause("notfoundstt");
        }
      })
      .catch(err => {
        this.setShowSttDetail(false);
        this.setError(true);
        if (!err.response) {
          this.setErrorCause("internet");
        }
        switch (err.response.data.code) {
          case 400:
            this.setErrorCause("notfoundstt");
            break;
          default:
            this.setErrorCause("server");
            break;
        }
      })
      .finally(() => this.setLoading(false));
  }

  @Mutation
  setLogStt(val: LogSttData) {
    this.logStt = val;
  }

  @Action
  initLogStt() {
    this.setError(false);
    this.setErrorCause("");
    this.setShowSttDetail(false);
  }

  @Mutation
  setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  setShowSttDetail(val: boolean) {
    this.showSttDetail = val;
  }

  @Mutation
  private async setFirst() {
    this.pagination.page = 1;
  }

  @Mutation
  public setAdvanceFilterData(value: AdvancedFilterElexysFailedBooking) {
    this.advanceFilterData = value;
  }

  @Mutation
  public setFilter(data: any) {
    this.filter = {
      search: data.search,
      sort: data.sort
    };
  }

  @Mutation
  public setElexysFailedBookingList(data: ElexysFailedBookingEntities) {
    this.elexysFailedBookingList = data;
  }

  @Mutation
  public setLoading(status: boolean) {
    this.isLoading = status;
  }

  @Mutation
  public setErrorCause(err: string) {
    this.errorCause = err;
  }

  @Mutation
  public setOpenModalFailedBooking(value: boolean) {
    this.isOpenModalDownloadFailedBooking = value;
  }

  @Mutation
  public setSuccessDownloadFailedBooking(value: boolean) {
    this.isSuccessDownloadFailedBooking = value;
  }

  @Mutation
  public setshowModalSuccessRetryBooking(boolean: boolean) {
    this.showModalSuccessRetryBooking = boolean;
  }

  @Mutation
  public setModalRetryBooking(params: boolean) {
    this.showModalRetryBooking = params;
  }
}

export const IntegrationMonitoringController = getModule(
  IntegrationMonitoringStore
);
